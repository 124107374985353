import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";


import Landing from "./pages/landing"; 
import Signup from "./pages/signup";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />

        <Route path="/signup" element={<Signup />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
