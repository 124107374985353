import React from "react";
import { Link } from "react-router-dom";

function Demo() {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <iframe
        width="800"
        height="500"
        src="https://www.youtube.com/embed/Em3mX1Ns8Ek"
        title="YouTube video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <div className="mt-4">
        <Link
          to="/signup"
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          Sign up for xNilio Beta
        </Link>
      </div>
    </div>
  );
}

export default Demo;
