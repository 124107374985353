import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../context/theme-context";

function Signup() {
  const { theme } = useContext(ThemeContext);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    linkedin: ""
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const googleFormUrl =
    "https://docs.google.com/forms/d/e/1FAIpQLSdhV1iQStBuaRS0xYcDzPfSSSa2voruV9TMNboiFHcNtD_7bw/formResponse";

  const entryName = "entry.682301940";
  const entryEmail = "entry.988040989";
  const entryCompany = "entry.642325843";
  const entryLinkedIn = "entry.409341512";

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email) {
      alert("Name and Email are required.");
      return;
    }

    const emailPattern = /\S+@\S+\.\S+/;
    if (!emailPattern.test(formData.email)) {
      alert("Please enter a valid email address.");
      return;
    }

    if (
      formData.linkedin &&
      !/^https:\/\/(www\.)?linkedin\.com\/.*$/.test(formData.linkedin)
    ) {
      alert("Please provide a valid LinkedIn URL (e.g. https://www.linkedin.com/in/...).");
      return;
    }

    const gfData = new FormData();
    gfData.append(entryName, formData.name);
    gfData.append(entryEmail, formData.email);
    gfData.append(entryCompany, formData.company);
    gfData.append(entryLinkedIn, formData.linkedin);

    fetch(googleFormUrl, {
      method: "POST",
      mode: "no-cors",
      body: gfData,
    })
      .then(() => {
        setSubmitted(true);
      })
      .catch((err) => {
        console.error(err);
        alert("Something went wrong while submitting the form.");
      });
  };

  const logoSrc =
    theme === "dark"
      ? "/assets/xnilio_logo_dark_mode.png"
      : "/assets/xnilio_logo_light_mode.png";

  if (submitted) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center">
        <h1 className="text-3xl mb-4">Thanks for signing up!</h1>
        <p className="mb-4">You will receive updates on our beta launch.</p>
        <Link
          to="/"
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          Return to Home
        </Link>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-row items-center">
      <div className="hidden md:flex w-1/2 items-center justify-center">
        <img src={logoSrc} alt="xNilio Logo" className="max-w-xs" />
      </div>

      <div className="flex flex-col w-full md:w-1/2 items-center justify-center p-8">
        <h1 className="text-4xl mb-6">Sign up for xNilio Beta</h1>
        <form onSubmit={handleSubmit} className="w-full max-w-sm">
          <label className="block mb-2 text-sm font-bold" htmlFor="name">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            className="w-full mb-4 p-2 border border-gray-300 rounded dark:border-gray-700 dark:bg-gray-900"
            id="name"
            name="name"
            type="text"
            value={formData.name}
            onChange={handleChange}
            required
          />

          <label className="block mb-2 text-sm font-bold" htmlFor="email">
            Email <span className="text-red-500">*</span>
          </label>
          <input
            className="w-full mb-4 p-2 border border-gray-300 rounded dark:border-gray-700 dark:bg-gray-900"
            id="email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
          />

          <label className="block mb-2 text-sm font-bold" htmlFor="company">
            Company (Optional)
          </label>
          <input
            className="w-full mb-4 p-2 border border-gray-300 rounded dark:border-gray-700 dark:bg-gray-900"
            id="company"
            name="company"
            type="text"
            value={formData.company}
            onChange={handleChange}
          />

          <label className="block mb-2 text-sm font-bold" htmlFor="linkedin">
            LinkedIn (Optional)
          </label>
          <input
            className="w-full mb-4 p-2 border border-gray-300 rounded dark:border-gray-700 dark:bg-gray-900"
            id="linkedin"
            name="linkedin"
            type="url"
            placeholder="https://www.linkedin.com/in/..."
            value={formData.linkedin}
            onChange={handleChange}
          />

          <div className="flex justify-between items-center w-full mt-4">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Sign Up
            </button>

            <Link
              to="/"
              className="text-blue-600 underline hover:text-blue-800"
            >
              Back to Home
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Signup;
