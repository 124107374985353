import React from "react";
import Home from "./home";
import Mission from "./mission";
import Demo from "./demo";
import Team from "./team";
import Nav from "../components/nav";

function Landing() {
  return (
    <div className="relative w-full h-screen">
      {/* <Nav /> */}
      <div className="snap-y snap-mandatory h-full overflow-y-scroll scroll-smooth">
        <section id="home" className="snap-start w-full h-screen flex">
          <Home />
        </section>
        {/* <section id="mission" className="snap-start w-full h-screen flex">
          <Mission />
        </section> */}
        
        <section id="demo" className="snap-start w-full h-screen flex">
          <Demo />
        </section>
        
        {/* <section id="team" className="snap-start w-full h-screen flex">
          <Team />
        </section> */}
      </div>
    </div>
  );
}

export default Landing;
